import "./Page.css";
import React, { useState, useEffect } from 'react';
import { API_URL } from '../../index';
import axios from 'axios'
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ProceduresList from '../Procedures/ProceduresList';
import ErrorWrapper from "../PageComponents/ErrorWrapper";


const URL = "pages/procedures/";

const ProceduresPage = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const [path, setPath] = useState([{ link: "", title: "Методики та процедури" }]);

    useEffect(() => {
        window.scrollTo(0, 0);
        axios.get(API_URL + URL).then(res => {
            setData(res.data);
            try {
                setPath([{ link: "", title: data.ukr_name.split('|')[0] }]);
            }
            catch { console.log("Unable to set title in the path!"); console.log(data); }
        }).catch(err => setError(true));
    }, []);

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={data} />
            <div className="frame last">
                <h1 className="main-header">{data.ukr_name && data.ukr_name.toUpperCase().split('|').join('\n')}</h1>
                <ProceduresList />
            </div>
            <Footer nested="1" />
        </ErrorWrapper>
        );
}

export default ProceduresPage;