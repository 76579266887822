import './FieldDetailed.css';
import React, { useState, useEffect, useContext } from 'react';
import axios from "axios";
import { useParams } from 'react-router';
import { API_URL, prepareImages } from '../../index';
import * as DOMPurify from 'dompurify';
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import Footer from '../PageComponents/Footer';
import Header from '../PageComponents/Header';
import ErrorWrapper from "../PageComponents/ErrorWrapper";
import RouteUrl from '../RouteEnum';
import ReviewSwiper from '../PageComponents/ReviewSwiper';
import Map from "../PageComponents/Map";


const fieldsURL = "medical-fields/";

const FieldDetailed = () => {
    const { id } = useParams();
    const [field, setField] = useState([]);
    const [cleanTitle, setCleanTitle] = useState('');
    const [headerData, setHeaderData] = useState({});
    const { modalOn, modalData } = useContext(ModalContext);
    const [error, setError] = useState(false);

    const BUTTON_TEXT = "ЗАПИСАТИСЯ НА КОНСУЛЬТАЦІЮ";
    const path = [{ link: "../" + RouteUrl.medicalFields, title: "Напрями" }, { link: "", title: cleanTitle }]

    const onClick = () => {
        modalData(cleanTitle, BUTTON_TEXT);
        modalOn();
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        getField();
    }, []);

    const getField = () => {
        axios.get(API_URL + fieldsURL + id).then(data => {
            setField(data.data);
            setHeaderData({
                image: data.data.image,
                SEO_title: data.data.SEO_title,
                SEO_keywords: data.data.SEO_keywords,
                SEO_desc: data.data.SEO_desc
            });
            const [number, ...rest] = data.data.title1.split('.');
            setCleanTitle(rest.length > 0 ? rest.join('.') : number);
        }).catch(err => setError(true));
    };

    

    return (
        <ErrorWrapper error={error}>
            <Header path={path} data={headerData} />
            <div className="frame">
                {cleanTitle && <h1 className="main-header">{cleanTitle.toUpperCase().split('|').join('\n')}</h1>}
                <div className="field-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(field.text1)) }}></div>

                {field.title2 && <h1 className="main-header">{field.title2.toUpperCase().split('|').join('\n')}</h1>}
                <div className="field-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(field.text2)) }}></div>

                <ReviewSwiper />

                {field.title3 && <h1 className="main-header">{field.title3.toUpperCase().split('|').join('\n')}</h1>}
                <div className="field-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(field.text3)) }}></div>
                <Map />
                <div style={{ height: "65px" }}>&nbsp;</div>
                <div className="field-button-div"><button className="field-button" onClick={onClick} >
                    <div className="field-button-content">
                        <div className="message-svg">&nbsp;</div><h2 className="borderless" style={{ fontSize: "14px", textAlign: "left" }}>{BUTTON_TEXT}</h2>
                    </div>
                </button></div>
            </div>
            <Footer nested="2" />
        </ErrorWrapper>
    );
};

export default FieldDetailed;