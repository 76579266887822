import { useContext } from 'react';
import ModalContext from '../MakeAppointmentModal/ModalProvider';
import MenuHeaderDesktop from "../PageComponents/MenuHeaderDesktop";
import { LOCAL_BUTTON_TEXT, BUTTON_TEXT } from '../FullPages/IndexPage';
import * as DOMPurify from 'dompurify';
import { prepareImages, phoneToCall } from '../../index';

import './IndexStyle.css';
import clock from './icons/clock.svg';
import phone from './icons/phone.svg';
import location from './icons/location.svg';

const IndexHeaderDesktop = ({ pageData, contacts }) => {
    const { modalOn, modalData } = useContext(ModalContext);

    const onClick = () => {
        modalData("Прийом у лікаря", BUTTON_TEXT);
        modalOn();
    };

    return (
        <>
            <div style={{ minHeight: "90vh" }}></div>
            <div className="index-header">
                <div className="header">
                    <div className="header-padding"></div>
                    <MenuHeaderDesktop transparent nested="0" />
                    <div className="frame" style={{ minHeight: "calc(90vh - 60px)", height: "fit-content", position: "relative" }}>
                        <div style={{ height: "50px" }}>&nbsp;</div>
                        <div className="index-header-text">
                            <h1 className="index-header-title">{pageData.title1}</h1>
                            <div className="index-header-plaintext" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(pageData.text1)) }}></div>
                            <button className="index-header-button" onClick={onClick}>{LOCAL_BUTTON_TEXT}</button>
                            <div style={{height: "100px"}}>&nbsp;</div>
                        </div>
                        <div className="index-header-image"><img src={pageData.image} style={{ width: "100%", maxWidth: "600px" }} alt="header" /></div>
                    </div>
                </div>
            </div>
            <div className="frame last" >
                <div style={{ position: "relative" }}>
                    <div className="index-contacts-block">
                        <div className="index-contacts-container">
                            <div className="index-contacts-icon"><img src={clock} alt="clock" /></div>
                            <div className="index-contacts-text">
                                {contacts.workhours && contacts.workhours.map(item => (
                                    <div key={"index-workhours-" + item.pk} style={{ display: "inline-block", width: "165px" }}>
                                        <div style={{ fontWeight: "700", float: "left" }}>{item.title}</div><div style={{ float: "right" }}>{item.time}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="index-contacts-container">
                            <div className="index-contacts-icon"><img src={phone} alt="phone" /></div>
                            <div className="index-contacts-text">
                                <div style={{ display: "flex", flexWrap: "wrap", width: "230px", gap: "0px 20px" }}>
                                    {contacts.phones && contacts.phones.map(item => (
                                        <div key={"index-phones-" + item.pk}>
                                            <a href={"tel:" + phoneToCall(item.number)} style={{ color: "inherit", textDecoration: "underline" }}>{item.number.replace("+38 ", "")}</a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="index-contacts-container">
                            <div className="index-contacts-icon"><img src={location} alt="location" /></div>
                            <div className="index-contacts-text">
                                {contacts.addresses && contacts.addresses.map(item => (
                                    <a href={"http://maps.google.com/?q=" + item.address.replace(/<[^]*?>/g, '')} target="_blank"  >
                                        <div key={"index-addresses-" + item.pk}
                                            style={{ display: "inline-block", width: "200px", textDecoration: "underline" }}
                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prepareImages(item.address)) }} />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="index-contacts-block">
                        <div className="shadow-index-contacts-container"></div>
                        <div className="shadow-index-contacts-container"></div>
                        <div className="shadow-index-contacts-container"></div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default IndexHeaderDesktop;

//{width > 700 ? (<MenuHeaderDesktop transparent nested="0" />) : (<MenuHeaderMobile transparent nested="0" />)}