import axios from "axios";
import { API_URL, CACHE_TIME } from '../../index';
import FieldsPreview from './FieldsPreview';
import { useQuery } from 'react-query';

const fieldsURL = "medical-fields/";

const FieldsList = () => {
    const fields = useQuery('medical-fields', () => axios.get(API_URL + fieldsURL).then(response => response.data), { cacheTime: CACHE_TIME });

    const sortFields = (field1, field2) => {
        const arrField_1 = field1.split('.');
        const arrField_2 = field2.split('.');
        if (arrField_1.length > 1 && arrField_2.length > 1) {
            return +arrField_1[0] > +arrField_2[0] ? 1 : -1;
        }
        else if (arrField_1.length === 1 && arrField_2.length === 1) {
            return arrField_1[0].toUpperCase() > arrField_2[0].toUpperCase() ? 1 : -1;
        }
        else {
            return arrField_1.length > arrField_2.length ? -1 : 1;
        }
    }

    return (
        <div className="preview-list">
            {!fields || !fields.data || fields.data.length <= 0 ? (<></>) :
                fields.data.sort((a, b) => sortFields(a.title1, b.title1)).map(field => (<FieldsPreview field={field} key={"medicalfield" + field.pk} />
                ))
            }
        </div>
    );
};

export default FieldsList;